import React, { useEffect } from "react";
import {
  CloudUploadIcon,
  DatabaseIcon,
  PaperAirplaneIcon,
  ServerIcon,
} from "@heroicons/react/solid";

import bgImg from "../assets/suivipostimage.png";

import Typed from "react-typed";

const Hero = () => {
  
  useEffect(() => {
    document.title = "SuiviPost";
  }, []);

  return (
    <div
      name="home"
      className="w-full h-screen bg-white flex flex-col justify-between pt-16 md:pt-0"
    >
      <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
        <div className="flex flex-col justify-center items-center md:items-start w-full px-2 py-8 ">
          <Typed
            className="py-3 text-4xl md:text-5xl font-bold text-blue-400 "
            strings={["Gérez...", "Suivez...", "Et optimisez!"]}
            typeSpeed={40}
            backSpeed={50}
            loop
          ></Typed>
          <h1 className="text-4xl md:text-7xl font-bold">La livraison</h1>

          <h1 className="text-4xl md:text-7xl font-bold">
            De vos <span className="text-blue-400">colis</span>
          </h1>

          <div className="flex flex-col justify-center items-center md:items-start w-full px-2 py-8">
            <p className="text-2xl">avec la meilleure solution en ligne.</p>

            <button
              className="py-3 px-6 sm:w-[60%] my-4"
              onClick={() => {
                window.location.href = "https://forms.gle/jb1FiAW6bGe4WyECA";
              }}
            >
              Commencer
            </button>
          </div>
        </div>

         {/* image */}
        
          <img className="w-full" src={bgImg} alt="/" />
       
        {/* fin image */}


        {/* icônes */}
        <div
          className="absolute hidden  md:flex flex-col py-1 md:min-w-[760px] bottom-[5%]
                            mx-1 md:left-1/2 transform md:-translate-x-1/2 bg-white
                            border border-slate-300 rounded-xl text-center shadow-xl cursor-default"
        >
          <p>-----</p>

          <div className="flex justify-between flex-wrap px-4">
            <p className="flex px-4 py-2 text-slate-500">
              <CloudUploadIcon className="h-6 text-violet-600" /> App Security
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <DatabaseIcon className="h-6 text-violet-600" /> Dashboard{" "}
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <ServerIcon className="h-6 text-violet-600" /> Gestion des
              plannings
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <PaperAirplaneIcon className="h-6 text-violet-600" /> Tracking
            </p>
          </div>
        </div>
      </div>
      {/* fin icônes */}
    </div>
  );
};

export default Hero;
