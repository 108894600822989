import Navbar from "./components/navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Support from "./components/Support";
import AllInOne from "./components/AllInOne";
import Analytics from "./components/Analytics";
import MobileApp from "./components/mobileapp";
import Pricing from "./components/Pricing";
import OurClients from "./components/OurClients";
import Footer from "./components/Footer";


function App() {
  return (
    <>
    
      <Navbar />
      <Hero />
      <About />
      <Support />
      <AllInOne />
      <Analytics />
      <MobileApp />
      {/* <Pricing /> */}
      <OurClients />
      <Footer />
    </>
  );
}

export default App;
