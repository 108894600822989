import React from "react";
import analytics from "../assets/analytics.png";

const Analytics = () => {
  return (
    <div name="Analytics" className="w-full bg-white  px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img className=" w-[1000px] mx-auto my-4" src={analytics} alt="/" />
        <div className="flex flex-col justify-center">
          <p className="text-violet-600 font-bold ">
            Tableau De Bord Analytique
          </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Gérer l'analyse des données de manière centralisée
          </h1>
          <p>
            la gestion centralisée de l'analyse des données optimise les
            ressources, améliore la cohérence des analyses, facilite la prise de
            décision et renforce la collaboration entre les équipes, contribuant
            ainsi à une utilisation plus stratégique des données au sein de
            l'organisation.
          </p>
          <button className="bg-violet-600 text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3"
          onClick={() => {
            window.location.href = "https://forms.gle/jb1FiAW6bGe4WyECA";
          }}>
            Commencer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
