import React from "react";
import { CheckIcon } from "@heroicons/react/outline";

const AllInOne = () => {
  return (
    <div name="Fonctionnalités" className="w-full my-32">
      <div className="max-w-[1240px] mx-auto px-2">
        <h2 className="text-5xl font-bold text-center">
          Plateforme Tout-en-Un
        </h2>
        <p className="text-2xl py-8 text-gray-500 text-center">
          Faites confiance à Suivipost pour révolutionner votre gestion de livraisons
        </p>

        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 pt-4">
          <div className="flex">
            <div>
              <CheckIcon className="w-7 mr-4 text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-lg">
                Tableau de Bord Intuitif
              </h3>
              <p className="text-lg pt-2 pb-4">
                Accédez à un tableau de bord convivial qui vous offre une vue d'ensemble de vos opérations de livraison, des rapports détaillés et des indicateurs clés de performance.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <CheckIcon className="w-7 mr-4 text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Suivi en temps réel</h3>
              <p className="text-lg pt-2 pb-4">
                Avec Suivipost, vous avez une visibilité totale sur l'emplacement de chaque livraison en temps réel. Informez vos clients de l'état de leur livraison et réagissez rapidement aux imprévus.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <CheckIcon className="w-7 mr-4 text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Gestion des commandes</h3>
              <p className="text-lg pt-2 pb-4">
                Le logiciel enregistre les détails de chaque commande, y compris
                les informations du client, les articles commandés, les adresses
                d'expédition et de livraison, ainsi que les préférences
                spécifiques du client
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <CheckIcon className="w-7 mr-4 text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-lg">
                Notification Automatisée
              </h3>
              <p className="text-lg pt-2 pb-4">
                Informez vos clients de l'heure estimée d'arrivée, des retards éventuels et des mises à jour de leurs commandes grâce à des notifications automatisées.              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <CheckIcon className="w-7 mr-4 text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Intégration E-commerce</h3>
              <p className="text-lg pt-2 pb-4">
                Intégrez en toute simplicité Suivipost à votre plateforme e-commerce pour une gestion transparente des commandes en ligne et des livraisons.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <CheckIcon className="w-7 mr-4 text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Sécurité et confidentialité</h3>
              <p className="text-lg pt-2 pb-4">
                Parce que les informations de suivi et les détails des colis peuvent être sensibles, Suivipost s'engage à assurer la sécurité des données et à préserver la confidentialité des utilisateurs.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <CheckIcon className="w-7 mr-4 text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Rapports et analyses</h3>
              <p className="text-lg pt-2 pb-4">
                Nos fonctionnalités de reporting offrent aux entreprises la possibilité de surveiller leurs performances, d'analyser les tendances de livraison, d'identifier les points de congestion et d'optimiser leur efficacité opérationnelle.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <CheckIcon className="w-7 mr-4 text-green-600" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Planification Intelligente</h3>
              <p className="text-lg pt-2 pb-4">
                Suivipost utilise des algorithmes avancés pour optimiser la planification des itinéraires de livraison, ce qui permet de réduire les temps de trajet, d'économiser du carburant et d'améliorer la satisfaction du client.              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInOne;
