import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

import { MenuIcon, XIcon } from "@heroicons/react/outline";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);

  return (
    <div className="w-screen h-[80px] z-10 bg-white fixed drop-shadow-lg cursor-pointer">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <h1 className="text-3xl font-bold mr-4 sm:text-4xl text-transparent bg-gradient-to-r bg-clip-text from-black via-cyan-500 to-black">
            SuiviPost.
          </h1>
          <ul className="hidden md:flex">
            <li className="hover:bg-transparent hover:text-blue-400">
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li className="hover:bg-transparent hover:text-blue-400">
              <Link to="about" smooth={true} offset={-200} duration={500}>
                A propos
              </Link>
            </li>
            <li className="hover:bg-transparent hover:text-blue-400">
              <Link to="support" smooth={true} offset={-50} duration={500}>
                Support
              </Link>
            </li>
            <li className="hover:bg-transparent hover:text-blue-400">
              <Link
                to="Fonctionnalités"
                smooth={true}
                offset={-100}
                duration={500}
              >
                Fonctionnalités
              </Link>
            </li>
            <li className="hover:bg-transparent hover:text-blue-400">
              <Link to="Analytics" smooth={true} offset={-200} duration={500}>
                Analytiques
              </Link>
            </li>
            {/*<li className="hover:bg-transparent hover:text-blue-400">
              <Link to="pricing" smooth={true} offset={-50} duration={500}>
                Tarification
              </Link>
  </li>*/}
            <li className="hover:bg-transparent hover:text-blue-400">
              <Link to="clientslist" smooth={true} offset={-100} duration={500}>
                Nos Références
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden md:flex pr-4">
          {/*   <button className='border-none bg-transparent text-blue-400 mr-4'>
            S'identifier
          </button>*/}
          <button
            className="px-8 py-3"
            onClick={() => {
              window.location.href = "https://forms.gle/jb1FiAW6bGe4WyECA";
            }}
          >
            Demo
          </button>
        </div>
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8"}>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link onClick={handleClose} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="about"
            smooth={true}
            offset={-200}
            duration={500}
          >
            A propos
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="support"
            smooth={true}
            offset={-50}
            duration={500}
          >
            Support
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="Fonctionnalités"
            smooth={true}
            offset={-100}
            duration={500}
          >
            Fonctionnalités
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="Analytics"
            smooth={true}
            offset={-200}
            duration={500}
          >
            Analytiques
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="pricing"
            smooth={true}
            offset={-50}
            duration={500}
          >
            Pricing
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="clientslist"
            smooth={true}
            offset={-100}
            duration={500}
          >
            Nos Références
          </Link>
        </li>
        <div className="flex flex-col my-4">
          {/* <button className='bg-transparent text-violet-600 px-8 py-3 mb-4'>S'identifier</button> */}
          <button
            className="px-8 py-3"
            onClick={() => {
              window.location.href = "https://forms.gle/jb1FiAW6bGe4WyECA";
            }}
          >
            Demo
          </button>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
