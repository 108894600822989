import React from "react";
import appimg from "../assets/ applicationweb.png";

const MobileApp = () => {
  return (
    <div name="mobileapp" className="w-full bg-white  px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <p className="text-violet-600 font-bold ">
            Application Mobile IOS & Android{" "}
          </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Une Application Mobile pour une gestion de livraison optimale
          </h1>
          <p>
            Une application mobile conçue pour offrir une expérience pratique, conviviale et personnalisée aux utilisateurs, tout en vous permettant de gérer de manière optimale vos opérations de livraison et de maintenir un niveau élevé de satisfaction client.
          </p>
          {/*<button className=" bg-violet-600  text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3">
            Télécharger
  </button>*/}
        </div>
        <img className=" w-[1000px] mx-auto my-4" src={appimg} alt="/" />
      </div>
    </div>
  );
};
export default MobileApp;
