import React from "react";
import { clients } from "./Clientslist";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function OurClients() {
  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <div name="clientslist">
      <div className="mx-auto cursor-default ">
        <h2 className="text-5xl font-bold text-center">Nos Références</h2>
      </div>
      <br/>
      <br/>
      <br/>
      <div className="relative flex items-center">
        <MdChevronLeft
          className="opacity-50 cursor-pointer hover:opacity-100"
          onClick={slideLeft}
          size={40}
        />
        <div
          id="slider"
          className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {clients.map((item) => (
            <img
              className="w-[180px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300"
              style={{
                marginRight: "10px",
              }}
              src={item.img}
              alt="/"
            />
          ))}
        </div>
        <MdChevronRight
          className="opacity-50 cursor-pointer hover:opacity-100"
          onClick={slideRight}
          size={40}
        />
      </div>
    </div>
  );
}

export default OurClients;
