export const clients = [
    {
      id: 1,
      img: 'https://jj-delivery.suivipost.com/static/media/logo.7787ab3d.png',
    },

    {
      id: 2,
      img: 'https://eaglemen.suivipost.com/static/media/logo.2145d886.png',
    }
];