import React from "react";

import { PhoneIcon, ArrowSmRightIcon } from "@heroicons/react/outline";
import { ChipIcon, SupportIcon } from "@heroicons/react/solid";

import supportImg from "../assets/support.jpg";

const Support = () => {
  return (
    <div name="support" className="w-full mt-24">
      <div className="w-full h-[700px] bg-gray-900/90 absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src={supportImg}
          alt="/"
        />
      </div>

      <div className="max-w-[1240px] mx-auto text-white relative">
        <div className="px-4 py-12">
          <h2 className="text-3xl pt-8 text-slate-300 uppercase text-center">
            Support
          </h2>
          <h3 className="text-5xl font-bold py-6 text-center">
            Votre succès est notre priorité
          </h3>
          <p className="py-4 text-3xl text-slate-300">
            Notre équipe dévouée de professionnels hautement qualifiés est là pour garantir que votre expérience avec nos produits et services soit sans faille

          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black">
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <SupportIcon className="w-16 p-4 bg-violet-600 text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">Présentation démo</h3>
              <p className="text-gray-600 text-xl">
                Nous sommes disponibles à votre convenance pour vous proposer une démonstration, que ce soit pour explorer nos produits, découvrir nos services ou comprendre nos solutions. N'hésitez pas à nous contacter pour organiser cette présentation.
                <br /><br />
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <a href="https://forms.gle/jb1FiAW6bGe4WyECA">
                <p className="flex items-center text-violet-600">
                  Contactez nous <ArrowSmRightIcon className="w-5 ml-2" />
                </p>
              </a>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <PhoneIcon className="w-16 p-4 bg-violet-600 text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">Support technique</h3>
              <p className="text-gray-600 text-xl">
                Notre équipe est dévouée à assurer une expérience sans accroc et sans tracas pour vous. Votre réussite est notre principale préoccupation, c'est pourquoi notre équipe technique est prête à surmonter tous les obstacles pour vous garantir la meilleure expérience qui soit.
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <a href="https://forms.gle/jb1FiAW6bGe4WyECA">
                <p className="flex items-center  text-violet-600">
                  Contactez nous <ArrowSmRightIcon className="w-5 ml-2" />
                </p>
              </a>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <ChipIcon className="w-16 p-4 bg-violet-600 text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">Une solution sur mesure</h3>
              <p className="text-gray-600 text-xl">
                Nous nous engageons à répondre à vos besoins de manière personnalisée, que ce soit par le biais de solutions sur mesure, de projets spécifiques ou d'ajustements particuliers que vous envisagez. Votre satisfaction demeure notre objectif prioritaire.
                <br /><br />
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <a href="https://forms.gle/jb1FiAW6bGe4WyECA">
                <p className="flex items-center text-violet-600">
                  Contactez nous <ArrowSmRightIcon className="w-5 ml-2" />
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
