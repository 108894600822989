import React from 'react'

const About = () => {
    return (
        <div name='about' className='w-full my-32'>
            <div className='max-w-[1240px] mx-auto'>
                <div className='text-center'>
                    <h2 className='text-5xl font-bold'>À propos de Suivipost</h2>
                    <p className='text-3xl py-6 text-gray-500'>Chez Suivipost, nous nous engageons à réinventer la manière dont le monde gère les livraisons. Notre mission est de fournir des solutions de pointe dans le domaine de la gestion des livraisons, afin d'optimiser les opérations logistiques de nos clients et d'améliorer l'expérience de leurs clients finaux.</p>
                    <p className='text-3xl py-6 text-gray-500'>Forts d'une expertise approfondie dans le domaine de la logistique et du e-commerce, nous avons créé une plateforme puissante et flexible qui répond aux défis uniques de chaque entreprise. Que vous soyez une petite entreprise locale ou une grande entreprise internationale, notre système de gestion des livraisons, Suivipost, peut être adapté pour répondre à vos besoins spécifiques.</p>

                </div>

                <div className='grid md:grid-cols-3 gap-1 px-2 text-center'>
                    <div className='border py-8 rounded-xl shadow-xl' >
                        <p className='text-6xl font-bold text-indigo-600'>100%</p>
                        <p className='text-gray-400 mt-2'>Garanti</p>
                    </div>
                    <div className='border py-8 rounded-xl shadow-xl' >
                        <p className='text-6xl font-bold text-indigo-600'>7/7</p>
                        <p className='text-gray-400 mt-2'>Support Technique</p>
                    </div>
                    <div className='border py-8 rounded-xl shadow-xl' >
                        <p className='text-6xl font-bold text-indigo-600'>+100K</p>
                        <p className='text-gray-400 mt-2'>Transactions</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About